<template>
  <GameIntro
    :game="game"
    :games-url="gamesUrl"
    :highscore-today="highscoreToday"
    :member-can-play-game="memberCanPlayGame"
  />

  <section class="container grid grid-cols-1 lg:grid-cols-2 gap-4 mb-6 md:mb-12">
    <div class="bg-white shadow rounded w-full p-4">
      <h2 class="font-bold mb-4">
        {{ __('games.show.winners.title') }}
      </h2>

      <div class="flex flex-wrap mt-4 mb-2 text-xs md:text-sm">
        <span class="w-3/12 font-bold">
          {{ __('games.show.winners.date') }}
        </span>
        <span class="w-2/12 font-bold pl-3">
          {{ __('games.show.winners.score') }}
        </span>
        <span class="w-3/12 font-bold pl-3">
          {{ __('games.show.winners.amount') }}
        </span>
        <span class="w-4/12 font-bold pl-3">
          {{ __('games.show.winners.name') }}
        </span>
      </div>

      <div class="divide-solid divide-y divide-gray-300 mb-3 md:mb-6 md:border-t md:border-gray-300">
        <template v-if="winners.data && winners.data.length > 0">
          <div
            v-for="(highscore, i) in winners.data"
            :key="`highscore-${i}`"
            class="flex flex-wrap py-2"
          >
            <span class="w-3/12 font-bold text-xs leading-5 md:text-base">
              {{ highscore.date }}
            </span>
            <span class="w-2/12 text-xs leading-5 md:text-base pl-3">
              {{ highscore.score }}
            </span>
            <span class="w-3/12 text-xs leading-5 md:text-base pl-3">
              {{ highscore.reward }}
            </span>
            <span class="w-4/12 text-xs leading-5 md:text-base pl-3 truncate">
              {{ highscore.username }}
            </span>
          </div>
        </template>

        <div
          v-else
          class="flex flex-wrap py-2"
        >
          <span class="w-full text-center text-xs leading-5 md:text-base">
            {{ __('games.show.winners.no-results') }}
          </span>
        </div>
      </div>

      <div
        v-if="winners.meta && winners.meta.pagination && winners.meta.pagination.links.length > 3"
      >
        <Pagination
          :pagination="winners.meta.pagination"
          :on-each-side="3"
        />
      </div>
    </div>

    <div class="bg-white shadow rounded w-full p-4">
      <h2 class="font-bold mb-4">
        {{ __('games.show.member-sessions.title') }}
      </h2>

      <div class="flex flex-wrap mt-4 mb-2 text-xs md:text-sm">
        <span class="w-9/12 font-bold">
          {{ __('games.show.member-sessions.date') }}
        </span>
        <span class="w-3/12 font-bold pl-3">
          {{ __('games.show.member-sessions.score') }}
        </span>
      </div>

      <div class="divide-solid divide-y divide-gray-300 mb-3 md:mb-6 md:border-t md:border-gray-300">
        <template v-if="memberSessions.data && memberSessions.data.length > 0">
          <div
            v-for="(session, i) in memberSessions.data"
            :key="`session-${i}`"
            class="flex flex-wrap py-2"
          >
            <span class="w-9/12 text-xs font-bold leading-5 md:text-base">
              {{ session.date }}
            </span>
            <span class="w-3/12 text-xs leading-5 md:text-base pl-3 truncate">
              {{ session.score }}
            </span>
          </div>
        </template>

        <div
          v-else
          class="flex flex-wrap py-2"
        >
          <span class="w-full text-center text-xs leading-5 md:text-base">
            {{ __('games.show.member-sessions.no-results') }}
          </span>
        </div>
      </div>

      <div
        v-if="memberSessions.meta && memberSessions.meta.pagination && memberSessions.meta.pagination.links.length > 3"
      >
        <Pagination
          :pagination="memberSessions.meta.pagination"
          :on-each-side="3"
        />
      </div>
    </div>
  </section>
</template>

<script>
import GameIntro from './Components/Intro.vue';
import Pagination from '../../../Components/Pagination.vue';

export default {
  components: {
    GameIntro, Pagination,
  },

  props: {
    game: {
      type: Object,
      required: true,
    },

    gamesUrl: {
      type: String,
      required: true,
    },

    highscoreToday: {
      type: Object,
      required: true,
    },

    winners: {
      type: Object,
      required: true,
    },

    memberSessions: {
      type: Object,
      required: true,
    },

    memberCanPlayGame: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

