<template>
  <InertiaHead :title="title ? `${title} - ${__('tenant.name')}` : __('tenant.name')">
    <meta
      v-if="description"
      head-key="description"
      name="description"
      :content="description"
    >

    <link
      v-if="canonical"
      head-key="canonical"
      rel="canonical"
      :href="canonical"
    >

    <Component
      :is="'script'"
      v-if="structuredData"
      type="application/ld+json"
    >
      {{ structuredData }}
    </Component>

    <slot />
  </InertiaHead>
</template>

<script>
import {Head as InertiaHead} from '@inertiajs/vue3';

export default {
  components: {
    InertiaHead,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },

    description: {
      type: [String, null],
      required: false,
      default: null,
    },

    canonical: {
      type: [String, null],
      required: false,
      default: null,
    },

    structuredData: {
      type: [String, null],
      required: false,
      default: null,
    },
  },
};
</script>
