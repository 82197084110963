<template>
  <div
    v-if="pagination.prev_page_url || pagination.next_page_url"
    class="md:hidden flex justify-between w-full text-md py-3"
  >
    <div
      v-if="!pagination.prev_page_url"
      class="underline opacity-50"
    >
      {{ __('pagination.previous') }}
    </div>
    <Link
      v-else
      :href="pagination.prev_page_url"
      class="underline hover:no-underline cursor-pointer"
    >
      {{ __('pagination.previous') }}
    </Link>
    <div
      v-if="!pagination.next_page_url"
      class="underline opacity-50"
    >
      {{ __('pagination.next') }}
    </div>
    <Link
      v-else
      :href="pagination.next_page_url"
      class="underline hover:no-underline cursor-pointer"
    >
      {{ __('pagination.next') }}
    </Link>
  </div>

  <div
    v-if="pagination.links.length > 3"
    class="hidden md:flex border-gray-200 border flex-wrap justify-center divide-solid divide-x divide-gray-200 items-center bg-white md:mx-auto rounded md:rounded-none text-sm"
  >
    <template
      v-for="(link, i) in pagination.links"
      :key="`page-link-${i}`"
    >
      <template v-if="showLink(link)">
        <Link
          v-if="link.url"
          class="no-style text-center py-2 px-3 hover:font-bold"
          :class="{ 'font-bold': link.active }"
          :href="link.url"
        >
          <template v-if="link.label === __('pagination.previous')">
            <span class="material-symbols-outlined align-middle">
              chevron_left
            </span>
          </template>
          <template v-else-if="link.label === __('pagination.next')">
            <span class="material-symbols-outlined align-middle">
              chevron_right
            </span>
          </template>
          <template v-else>
            {{ link.label }}
          </template>
        </Link>
        <div
          v-else
          class="no-style text-center py-2 px-3 hover:font-bold"
          :class="{ 'font-bold': link.active }"
        >
          <template v-if="link.label === __('pagination.previous')">
            <span class="material-symbols-outlined align-middle">
              chevron_left
            </span>
          </template>
          <template v-else-if="link.label === __('pagination.next')">
            <span class="material-symbols-outlined align-middle">
              chevron_right
            </span>
          </template>
          <template v-else>
            {{ link.label }}
          </template>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    pagination: {
      type: Object,
      required: true,
    },

    onEachSide: {
      type: Number,
      required: false,
      default: null,
    },
  },

  methods: {
    showLink(link) {
      if (this.onEachSide === null || this.pagination.current_page > this.pagination.last_page) {
        return true;
      }

      if (link.label === '...' || link.label === this.__('pagination.next') || link.label === this.__('pagination.previous')) {
        return true;
      }

      const earliestPage = this.pagination.current_page - 3;
      const latestPage = this.pagination.current_page + 3;

      return link.label >= earliestPage && link.label <= latestPage;
    },
  },
};
</script>
