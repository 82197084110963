<template>
  <Head :title="title ?? __('games.show.title', {game: game.name})" />

  <Breadcrumbs
    :pages="[
      {name: __('games.index.title'), href: route('frontend.games.index')},
    ]"
    :current-page="game.name"
  />

  <section class="container mb-6">
    <h1 class="text-2xl md:text-3xl font-bold text-center mb-6">
      {{ title ?? __('games.show.title', {game: game.name}) }}
    </h1>
  </section>

  <section class="container mb-4 md:mb-6">
    <div class="bg-white shadow grid grid-cols-1 md:grid-cols-2">
      <div class="md:p-4">
        <img
          :src="game.media.url"
          :alt="game.name"
          :title="game.name"
          class="object-none md:object-fill max-h-[132px] md:max-h-full w-full"
        >
      </div>

      <div class="relative p-4">
        <div class="hidden md:block">
          <template v-if="highscoreToday && typeof highscoreToday === 'object' && 'score' in highscoreToday">
            <div class="float-right rounded shadow text-center ml-4 mb-4 p-4 min-w-fit h-fit">
              <div class="text-lg font-bold">
                {{ __('games.show.highscore-today.today') }}
              </div>
              <div class="font-bold text-orange text-5xl my-3">
                {{ highscoreToday.score }}
              </div>
              <div class="text-sm">
                {{ __('games.show.highscore-today.by', {name: highscoreToday.username}) }}
              </div>
            </div>
          </template>
        </div>

        <!-- eslint-disable vue/no-v-html -->
        <div
          class="content hidden md:block mb-4"
          v-html="game.description"
        />
        <!-- eslint-enable -->

        <div
          class="md:hidden overflow-y-hidden"
          :class="{ 'max-h-[100px]': !readMore }"
        >
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="content"
            v-html="game.description"
          />
          <!-- eslint-enable -->
        </div>

        <div
          v-show="!readMore"
          class="md:hidden cursor-pointer absolute w-full text-center text-lg text-blue underline hover:no-underline pt-20 mb-8 bottom-20 bg-gradient-to-t from-white"
          @click="readMore = true"
        >
          {{ __('games.show.read-more') }}
        </div>

        <div class="mb-4 md:mb-0">
          <p class="mt-6 md:mt-0 mb-2 md:mb-3.5 md:text-xl font-bold text-center md:text-left">
            <span v-if="game.slug === 'scratch'">{{ __('games.win-max', {reward: game.reward }) }}</span>
            <span v-else>{{ __('games.win', {reward: game.reward }) }}</span>
          </p>

          <template v-if="memberCanPlayGame">
            <FullscreenModal ref="game-modal">
              <template #modal-button="{ openModal }">
                <BlueSmallButton
                  classes="md:w-fit md:px-8"
                  @click="openModal"
                >
                  {{ __('games.play') }}
                </BlueSmallButton>
              </template>

              <template #modal-content>
                <iframe
                  :src="game.url"
                  class="h-[100dvh] w-full"
                />
              </template>
            </FullscreenModal>
          </template>
          <template v-else>
            <div class="text-red">
              {{ cannotPlayErrorMessage ?? __('games.show.maximum-games-today-reached') }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>

  <section class="container md:hidden mb-4 md:mb-6">
    <template v-if="highscoreToday && typeof highscoreToday === 'object' && 'score' in highscoreToday">
      <div class="bg-white shadow rounded w-full p-4 text-center">
        <div class="text-lg font-bold">
          {{ __('games.show.highscore-today.today') }}
        </div>
        <div class="font-bold text-orange text-5xl my-3">
          {{ highscoreToday.score }}
        </div>
        <div class="text-sm">
          {{ __('games.show.highscore-today.by', {name: highscoreToday.username}) }}
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import FullscreenModal from '../../../../Components/FullscreenModal.vue';
import BlueSmallButton from '../../../../Components/Buttons/BlueSmall.vue';

export default {
  components: {
    BlueSmallButton, FullscreenModal,
  },

  props: {
    game: {
      type: Object,
      required: true,
    },

    gamesUrl: {
      type: String,
      required: true,
    },

    highscoreToday: {
      type: Object,
      required: false,
      default: () => null,
    },

    title: {
      type: String,
      required: false,
      default: null,
    },

    memberCanPlayGame: {
      type: Boolean,
      required: false,
      default: true,
    },

    cannotPlayErrorMessage: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      readMore: false,
    };
  },

  mounted() {
    window.addEventListener('message', this.handleMessage);
  },

  beforeUnmount() {
    window.removeEventListener('message', this.handleMessage);
  },

  methods: {
    handleMessage(event) {
      if (event.origin !== this.gamesUrl) {
        return;
      }

      if (event.data.type === 'finish-session') {
        this.$inertia.reload({only: ['highscoreToday', 'winners', 'memberSessions', 'memberCanPlayGame']});
      }

      if (event.data.type === 'close-modal') {
        this.$refs['game-modal'].closeModal();
      }
    },
  },
};
</script>
